.tableContainer {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;

    &::-webkit-scrollbar-thumb {
      background-color: #a0aec0;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f4f7fc;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: #a0aec0;
    }

    &::-webkit-scrollbar-track:horizontal {
      background-color: #f4f7fc;
    }
  }

  .tableHeadCell {
    background-color: #f4f7fc !important;
    font-weight: bold !important;
    color: #2c3e50 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tableBodyCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #687182 !important;
  }

  .tableFooterRow {
    display: flex !important;
    justify-content: flex-end !important;
    padding-right: 1rem;
  }
}
